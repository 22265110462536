import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "components/CustomButtons/Button.jsx"
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx"
import SEO from "../components/seo"
import LayoutCard from "../components/LayoutCard"
import { Result, Icon } from "antd"
import { Link } from "gatsby"

const styles = {
  loginPageStyle,
}

const Cotizador = () => {
  return (
    <>
      <SEO title="Recurso no encontrado" />
      <LayoutCard>
        <section className="bg-light py-5 rounded">
          <div class="container">
            <Result
              icon={
                <Icon
                  type="exclamation-circle"
                  theme="twoTone"
                  twoToneColor="#ff0000"
                />
              }
              title="404"
              subTitle="Lo sentimos, la pagina a la que trata de acceder no existe."
              extra={
                <Link to="/">
                  <Button color="primary">Inicio</Button>
                </Link>
              }
            />
          </div>
        </section>
      </LayoutCard>
    </>
  )
}

export default withStyles(styles)(Cotizador)
